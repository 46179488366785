import Vue from 'vue'
import api from '../../api'

const loadFromStore = function(key) {
  const json = localStorage.getItem(key)
  if (json) {
    return JSON.parse(json)
  }
}

const saveToStore = function(key, value) {
  if (value == null) {
    localStorage.removeItem(key)
  } else {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const namespaced = true

const initialState = function() {
  return {
    info: {},
    services: {},
    pickedServices: {}
  }
}

export const state = initialState

export const getters = {
  services: state => state.services,
  info: state => state.info,
  pickedServices: state => state.pickedServices,
  pickedAnyService: (state) => {
    var validPicks = 0
    for(var a in state.pickedServices) {
      for(var b in state.pickedServices[a]) {
        const requireDimension = state.services[a].areaCleaning
        for(var c in state.pickedServices[a][b]) {
          const qty = state.pickedServices[a][b][c]
          const length = state.pickedServices[a][b].length
          const width = state.pickedServices[a][b].width
          const priceKey = state.pickedServices[a][b].priceKey
          const price = state.services[a].prices[priceKey][c] / 100.0
          if (qty > 0) {
            if (requireDimension && (b == 'area')) {
              if ((length > 0) && (width > 0)) {
                validPicks += 1
              }
            } else {
              validPicks += 1
            }
          }
        }
      }
    }
    return validPicks > 0
  },
  pickedTotal: (state, getters) => {
    var total = 0
    for(var a in state.pickedServices) {
      for(var b in state.pickedServices[a]) {
        const length = state.pickedServices[a][b].length
        const width = state.pickedServices[a][b].width
        const priceKey = state.pickedServices[a][b].priceKey
        const sectional = state.pickedServices[a][b].sectional
        const hasClean = state.pickedServices[a][b].clean > 0
        for(var c in state.pickedServices[a][b]) {
          var seats = state.pickedServices[a][b].seats
          if ((c != 'length') && (c != 'width') && (c != 'seats') && (c != 'sectional')) {
            const qty = state.pickedServices[a][b][c]
            var priceCents = 0
            if ( (qty > 0) && (hasClean) ) {
              if (state.services) {
                if (state.services[a].prices) {
                  var servicePriceKey = c
                  const priceInfo = state.services[a].prices[priceKey][servicePriceKey]
                  if (typeof(priceInfo) == 'object') {
                    if (priceInfo.base && (servicePriceKey == 'clean')) {
                      priceCents = priceInfo.base
                    }
                    if ( (priceKey == 'sofa') && (servicePriceKey == 'clean') ) {
                      seats -= 3
                    }
                    if (seats > 0) {
                      priceCents += priceInfo.per_seat * seats
                    }
                  }
                  if (typeof(priceInfo) == 'number') {
                    priceCents = priceInfo
                  }
                }
              }
              total += qty * priceCents / 100.0
              if (sectional) {
                total += 15
              }
            }
          }
        }
      }
    }
    return total
  }
}
export const mutations = {
  RESET(state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
      localStorage.removeItem(key)
    })
  },
  SET_SERVICE(state, { slug, service }) {
    Vue.set(state.services, slug, service)
    Vue.set(state.services[slug], 'areas', {})
  },
  SET_SERVICE_AREA(state, { serviceSlug, areaSlug, area }) {
    Vue.set(state.services[serviceSlug].areas, areaSlug, area)
  },
  REMOVE_SERVICE_AREA(state, { service, area }) {
    if (state.services[service] == null) {
      Vue.set(state.services, service, {})
    }
    if (state.services[service].areas == null) {
      Vue.set(state.services[service], 'areas', {})
    }
    Vue.delete(state.services[service].areas, area)
    if (state.pickedServices[service] == null) {
      Vue.set(state.pickedServices, service, {})
    }
    if (state.pickedServices[service][area] == null) {
      Vue.set(state.pickedServices[service], area, {})
    }
    Vue.delete(state.pickedServices[service], area)
    if (Object.entries(state.pickedServices[service]).length == 0) {
      Vue.delete(state.pickedServices, service)
    }
  },
  SET_SERVICE_PRICES(state, { serviceSlug, prices }) {
    Vue.set(state.services[serviceSlug], 'prices', prices)
  },
  SET_SERVICES_FOR_AREA(state, { serviceSlug, services }) {
    Vue.set(state.services[serviceSlug], 'services', services)
  },
  UPDATE_INFO(state, { info, value }) {
    Vue.set(state.info, info, value)
  },
  SET_ATTR(state, { attr, value }) {
    state[attr] = value
    saveToStore(attr, value)
  },
  ADD_SERVICE(state, { service, area, areaService, priceKey }) {
    if (state.pickedServices[service] == null) {
      Vue.set(state.pickedServices, service, {})
    }
    if (state.pickedServices[service][area] == null) {
      Vue.set(state.pickedServices[service], area, {})
      Vue.set(state.pickedServices[service][area], 'priceKey', priceKey)
    }
    if (state.pickedServices[service][area][areaService] == null) {
      Vue.set(state.pickedServices[service][area], areaService, 0)
    }
    state.pickedServices[service][area][areaService] += 1
    if ( (service == 'upholstery-common') && (area == 'sofa') ) {
      if (areaService == 'clean') {
        Vue.set(state.pickedServices[service][area], 'seats', 3)
      }
      state.pickedServices[service][area]['clean'] = 1
    }
    if ( (areaService == 'protect') || (areaService == 'deodorize') ) {
      if (state.pickedServices[service][area]['clean'] == null) {
        Vue.set(state.pickedServices[service][area], 'clean', 0)
      }
      if (state.pickedServices[service][area]['clean'] < state.pickedServices[service][area][areaService]) {
        state.pickedServices[service][area]['clean'] += 1
      }
    }
    //saveToStore(serviceKey, value)
  },
  REMOVE_SERVICE(state, { service, area, areaService }) {
    if (state.pickedServices[service] == null) {
      Vue.set(state.pickedServices, service, {})
    }
    if (state.pickedServices[service][area] == null) {
      Vue.set(state.pickedServices[service], area, {})
    }
    if (state.pickedServices[service][area][areaService] == null) {
      Vue.set(state.pickedServices[service][area], areaService, 0)
    }
    if (state.pickedServices[service][area][areaService] > 0) {
      state.pickedServices[service][area][areaService] -= 1
    }
    if (areaService == 'clean') {
      if (state.pickedServices[service][area]['clean'] < state.pickedServices[service][area]['protect']) {
        if (state.pickedServices[service][area]['protect'] > 0) {
          state.pickedServices[service][area]['protect'] -= 1
        }
      }
      if (state.pickedServices[service][area]['clean'] < state.pickedServices[service][area]['deodorize']) {
        if (state.pickedServices[service][area]['deodorize'] > 0) {
          state.pickedServices[service][area]['deodorize'] -= 1
        }
      }
    }
    if (state.pickedServices[service][area][areaService] == 0) {
      Vue.delete(state.pickedServices[service][area], areaService)
      if (areaService == 'clean') {
        state.pickedServices[service][area].sectional = false
        Vue.delete(state.pickedServices[service], area)
      } else {
        if (Object.entries(state.pickedServices[service][area]).length == 0) {
          Vue.delete(state.pickedServices[service], area)
          if (Object.entries(state.pickedServices[service]).length == 0) {
            Vue.delete(state.pickedServices, service)
          }
        }
      }
    }
    //saveToStore(serviceKey, value)
  },
  UPDATE_SERVICE_DIMENSION(state, { service, area, length, width, priceKey }) {
    if (state.pickedServices[service] == null) {
      Vue.set(state.pickedServices, service, {})
    }
    if (state.pickedServices[service][area] == null) {
      Vue.set(state.pickedServices[service], area, {})
      Vue.set(state.pickedServices[service][area], 'length', 0)
      Vue.set(state.pickedServices[service][area], 'width', 0)
    }
    Vue.set(state.pickedServices[service][area], 'priceKey', priceKey)
    if (length) {
      Vue.set(state.pickedServices[service][area], 'length', length * 1)
    }
    if (width) {
      Vue.set(state.pickedServices[service][area], 'width', width * 1)
    }
    //saveToStore(serviceKey, value)
  },
  UPDATE_SEATS(state, { service, area, seats, priceKey }) {
    if (state.pickedServices[service] == null) {
      Vue.set(state.pickedServices, service, {})
    }
    if (state.pickedServices[service][area] == null) {
      Vue.set(state.pickedServices[service], area, {})
      Vue.set(state.pickedServices[service][area], 'seats', 0)
    }
    Vue.set(state.pickedServices[service][area], 'priceKey', priceKey)
    Vue.set(state.pickedServices[service][area], 'seats', seats * 1)
  },
  UPDATE_SECTIONAL(state, { service, area, sectional, priceKey }) {
    if (state.pickedServices[service] == null) {
      Vue.set(state.pickedServices, service, {})
    }
    if (state.pickedServices[service][area] == null) {
      Vue.set(state.pickedServices[service], area, {})
    }
    Vue.set(state.pickedServices[service][area], 'priceKey', priceKey)
    Vue.set(state.pickedServices[service][area], 'sectional', sectional)
    if ( sectional && (service == 'upholstery-common') && (area == 'sofa') ) {
      state.pickedServices[service][area]['clean'] = 1
      Vue.set(state.pickedServices[service][area], 'seats', 3)
    }
  }
}

export const actions = {
  reset({ commit }) {
    return new Promise((resolve, reject) => {
      commit('RESET')
      resolve()
    })
  },
  setAttr({ commit }, attrAndValue) {
    commit('SET_ATTR', attrAndValue)
  },
  resume({ commit }) {
    //commit('SET_ATTR', { attr: 'promo_code', value: loadFromStore('promo_code') })
  },
  getServices({ commit, getters }) {
    return new Promise((resolve, reject) => {
      api.getHweServices().then(response => {
        for(var serviceIdx in response.data.services) {
          const service = response.data.services[serviceIdx]
          commit('SET_SERVICE', { slug: service.slug, service: service })
        }
        resolve()
      })
    })
  },
  getHweService({ commit }, serviceId) {
    return new Promise((resolve, reject) => {
      api.getHweService(serviceId).then(response => {
        const slug = response.data.slug
        for(var areaSlug in response.data.areas) {
          var area = response.data.areas[areaSlug]
          area.priceKey = areaSlug
          commit('SET_SERVICE_AREA', { serviceSlug: slug, areaSlug: areaSlug, area: area })
        }
        commit('SET_SERVICES_FOR_AREA', { serviceSlug: slug, services: response.data.services })
        commit('SET_SERVICE_PRICES', { serviceSlug: slug, prices: response.data.price_cents })
        resolve()
      })
    })
  }
}
